<template>
  <div class="overall">
    <div class="circle-box">
      <div class="circle" :style="`width:${circle_w}px;height:${circle_h}px`">
        <div class="origin" :style="`width:${box_w}px;height:${box_h}px;transform: rotate(${stard}deg);`">
          <div :style="`width:${box_w}px;height:${box_h}px;transform: rotate(${-stard}deg);`" class="img-box"
            v-for="(item, index) in menus" :key="index" @click="Turn(index)">
            <div class="box">
              <img class="content" :src="item.icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["menus"], 
  data() {
    const activeIndex = 0;
    let map = {
      [activeIndex]: 0,
    };

    for (let i = -1; i >= -3; i--) {
      let prevIndex = activeIndex + i;

      if (prevIndex < 0) {
        prevIndex = this.menus.length + prevIndex;
      }

      map[prevIndex] = i;
    }

    for (let i = 1; i <= 3; i++) {
      let nextIndex = activeIndex + i;

      if (nextIndex > (this.menus.length - 1)) {
        nextIndex = nextIndex - this.menus.length;
      }

      map[nextIndex] = i;
    }

    return {
      circle_w: 750, //圆盘的宽
      circle_h: 750, //圆盘的高
      box_w: 200, //小圆宽
      box_h: 200, //小圆高
      PI: 360, //分布角度360deg
      stard: 90, //起始角度
      stard_s: 0, //用来默认储存第一个初始值
      activeIndex: 0,
      map: map
    };
  },
  created() {
    this.stard_s = this.stard;
  },

  mounted() {
    this.init();
    this.Turn(this.activeIndex);
  },
  methods: {
    //初始化小圆点，根据计算使其分布到对应位置
    init() {
      let box = document.querySelectorAll(".img-box");
      let avd = this.PI / box.length; //每一个 img-box 对应的角度
      // console.log(avd,'length')
      let ahd = (avd * Math.PI) / 180; //每一个 img-box 对应的弧度
      let radius = this.circle_w / 2; //圆的半径
      for (let i = 0; i < box.length; i++) {
        box[i].style.left = Math.sin(ahd * i) * radius + "px";
        box[i].style.top = Math.cos(ahd * i) * radius + "px";
      }
    },
    //点击相对应小圆点，圆盘进行相对应角度的转动
    Turn(index) {
      
      //
      this.stard = this.stard + this.map[index] * (this.PI / this.menus.length);

      // console.log(index, this.stard)

      this.activeIndex = index;

      // 
      let map = {
        [index]: 0,
      };

      for (let i = -1; i >= -3; i--) {
        let prevIndex = index + i;

        if (prevIndex < 0) {
          prevIndex = this.menus.length + prevIndex;
        }

        map[prevIndex] = i;
      }

      for (let i = 1; i <= 3; i++) {
        let nextIndex = index + i;

        if (nextIndex > (this.menus.length - 1)) {
          nextIndex = nextIndex - this.menus.length;
        }

        map[nextIndex] = i;
      }

      this.map = map;

      // 
      const bx = document.querySelectorAll(".box");

      for (let i = 0; i < bx.length; i++) {
        if (i == index) {
          bx[i].classList.add("box-active");
        } else {
          bx[i].classList.remove("box-active");
        }

        if (index == 1) {
          bx[14].classList.add("box-prev3");
        } else {
          bx[14].classList.remove("box-prev3");
        }

        if (index == 0) {
          bx[13].classList.add("box-prev2");
        } else {
          bx[13].classList.remove("box-prev2");
        }

        if (i == index - 2) {
          bx[i].classList.add("box-prev2");
        } else {
          bx[i].classList.remove("box-prev2");
        }

        if (index == 14) {
          bx[1].classList.add("box-next2");
        } else {
          bx[1].classList.remove("box-next2");
        }

        if (index == 13) {
          bx[0].classList.add("box-next2");
        } else {
          bx[0].classList.remove("box-next2");
        }

        if (i == index + 2) {
          bx[i].classList.add("box-next2");
        } else {
          bx[i].classList.remove("box-next2");
        }
      }

      this.handleImg(index);
    },

    handleImg(index) {
      this.$emit("getPerson", index);
    },
  },
};
</script>

<style lang="less" scoped>
.overall {
  width: 380px;
  height: 864px;
  position: relative;
  top: -20px;
  left: -200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.circle-box {
  position: absolute;
  overflow: hidden;
  right: 0;
  width: 420px;

  .circle {
    transform: scale(0.9);
    width: 100%;
    height: 90%;
    border-radius: 50%;
    box-sizing: border-box;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50%;

    .origin {
      position: relative;
      transition: 0.2s; //控制圆盘的的旋转速率

      .img-box {
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
        transition: none !important;
        pointer-events: none;

        .box {
          pointer-events: all !important;
          width: 100%;
          height: 100%;
          transition: 0.3s;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 50%;
          transform: scale(0.8);
          cursor: pointer;
          color: white;
          font-size: 40px;
          // overflow: hidden;

          &:hover .content {
            opacity: 1;
            color: white;
          }

          .content {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

            &:after {
              content: "";
              position: absolute;
              top: 0;
              width: 144px;
              height: 144px;
              background-color: red;
              z-index: 2;
            }
          }
        }

        .box-next2 {
          img {
            position: relative;
            left: -24px;
          }
        }

        .box-next3 {
          img {
            position: relative;
            left: -24px;
          }
        }

        .box-prev2 {
          img {
            // position: relative;
            // left: -40px;
            margin-left: -40px;
          }
        }

        .box-prev3 {
          img {
            margin-left: -40px;
          }
        }

        .box-active {
          margin: 0 auto;
          transition-delay: 0.5s;
          transform: scale(1);
          background-image: url(../assets/part-03/bg-active.png);
          background-size: cover;
          animation: rotate2 10s linear infinite;

          .content {
            opacity: 1;
            font-size: 30px;
            box-sizing: border-box;
            text-align: center;
            animation: rotate3 10s linear infinite;
            // padding-right: 8px;
          }
        }
      }
    }
  }
}

@keyframes rotate2 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate3 {
  0% {
    -webkit-transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}
</style>
