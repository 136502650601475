<template>
  <div class="main-footer">
    <div class="footer-top">
      <div class="footer-top-box">
        <div class="title-follow">
          <img style="width:322px;height:86px;margin-top: -12px;" :src="$t('footer.title')" />
        </div>
        <div class="btn-skip">
          <ul style="list-style:none;">
            <li v-for="item in linkList" :key="item.link" style="cursor: pointer;"><img :src="item.src" @click="handleTo(item.link)" /></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="logo">
        <img style="width:92px;height:92px;margin-right:16px;margin-top: -20px;" src="@/assets/bottom-bar/大航海LOGO.png" />
        <img style="width:130px;height: 54px;border-left:1px solid #fff;padding-left: 16px;" src="@/assets/bottom-bar/电魂logo.png" />
      </div>
      <div class="bottom-text">
        <div class="bottom-title">
          <vui-link @click="handlePrivacy" style="margin-right: 16px;width:164px;height:20px;"><img :src="$t('footer.privacy')" /></vui-link>
          <!-- <vui-link @click="handleUser" style="width:164px;height:20px;"><img :src="$t('footer.user')" /></vui-link> -->
        </div>
        <div class="content">
          <img style="width:828px;height:50px;" :src="$t('footer.content')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      linkList:[
        {src:require('../../assets/bottom-bar/FB1.png'),link:'https://www.facebook.com/ImperialDestinyPOG/'},
        {src:require('../../assets/bottom-bar/discord1.png'),link:'https://discord.gg/ayeD5pW8BF'},
        {src:require('../../assets/bottom-bar/youtube1.png'),link:'https://www.youtube.com/@ImperialDestinyPathofGold'},
      ]
    }
  },
  methods:{
    handleTo(link){
      window.open(link,"_blank")
    },
    handlePrivacy(){
      window.open('https://docs.google.com/document/d/1JU49xPNpyl1Ay5TgEx3WVzO46fR72Nd2h1hPK7R0vMQ/edit',"_blank")
    },
    handleUser(){
      window.open('https://docs.google.com/document/d/1JU49xPNpyl1Ay5TgEx3WVzO46fR72Nd2h1hPK7R0vMQ/','_blank')
    }
  }
}
</script>

<style scoped>
.main-footer{
  /* width:1920px; */
  max-width: 1920px;
}
.footer-top{
  width:100%;
  height:402px;
  margin:0;
  background: url(../../assets/bottom-bar/bg-follow.png);
  background-size:100% auto;
}
.footer-top-box{
  height:402px;
  width:80%;
  margin: 0 auto;
}
.title-follow{
  width:80%;
  height:91px;
  position: relative;
  /* left:10%; */
  top:20%;
  background: url(../../assets/bottom-bar/bg-title.png);
  background-size:100% auto;
}
.btn-skip{
  width:80%;
  height:140px;
  position: relative;
  /* left:10%; */
  top:30%;
}
ul{
  list-style:none;
}
ul li{
  display:inline;
  margin-right: 72px;
}
ul li:last-child{
  margin-right: 0;
}
ul li img{
  width:143px;
  height:143px
}
.footer-bottom{
  width:100%;
  height:220px;
  background: #1c1a18;
}
.logo{
  height:100px;
  width:400px;
  position: relative;
  top:40%;
  left:20%;
  float: left;
}
.logo img{
  float:left;
}
.bottom-text{
  width:800px;
  height:120px;
  float: left;
  position: relative;
  top:30%;
  left:20%;
}
.bottom-title{
  width: 164px;
  margin-bottom: 16px;
  position: relative;
  left: -316px;
}
</style>