<template>
  <div class="show-popup">
    <div class="popup" @touchmove.prevent @mousewheel.prevent>
      <div class="popup-content">
        <img class="popup-content-img" :src="$t('home.text1')"
          style="position:relative;top:28%;left:31%" />
        <div class="btn-x" @click="handleClose"><img class="btn-x"
            src="@/assets/part-01/x.png" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  props:{
    showPopup:{
        type: Boolean,
        require: true,
    }
  },
  data(){
    return{
        popup: this.showPopup,
    }
  },
  methods:{
    handleClose(){
      this.popup = false
      console.log(this.popup)
      this.$emit('update', this.popup)
    }
  }
}
</script>

<style scoped>
.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup-content {
  background: url(../assets/part-01/popup.png);
  height: 246px;
  width: 684px;
  position: absolute;
  top: 40%;
  left: 34%;
}
.popup-content-img{
  width: 375.58px;
  height: 90px;
}

.btn-x {
  width: 12px;
  height: 12px;
  position: relative;
  top: -24px;
  left: 144px;
  cursor: pointer;
}
</style>