<template>
  <div class="main-charecters">
    <div class="charecters-title">
      <div class="title">
        <img style="
            width: 320px;
            height: 91px;
            position: relative;
            top: 94px;
            left: 332px;
          " :src="$t('characters.title')" />
      </div>
    </div>
    <div class="charecters-content">
      <div class="content">
        <ul class="person-left">
          <li v-for="(item, index) in charecters" :key="index" :class="[{ active: firest == index }]"
            style="cursor: pointer">
            <div class="btn-first">
              <img :src="item.firest" @click="handleFirst(index)" />
            </div>
          </li>
        </ul>
        <div class="person-main">
          <div class="person-main-right">
            <transition name="fade">
              <img v-if="showPerson" style="
                width: 807px;
                height: 819px;
                position: relative;
                top: -28px;
                left: -34px;
              " :src="charecters[firest].secound[secound].person" />
            </transition>

          </div>
          <transition name="fade">
            <div class="person-main-left" v-if="showPerson">
              <div class="person-name">
                <div class="person-name-img" style="width: 400px; position: relative; left: -32px">
                  <span><img style="height: 264px; width: 528px" :src="
                    translate(
                      'characters.name.' +
                      charecters[firest].secound[secound].title
                    )
                  " /></span>
                  <span v-if="charecters[firest].secound[secound].audio">
                    <vui-button type="text" class="btn-audio" @click="
                      handleAudio()
                    ">
                      <img style="width: 50px; height: 50px"
                        :src="!isPlaying ? require('@/assets/part-03/center-video.png') : require('@/assets/part-03/center-video-not.png')" />
                    </vui-button>  
                    <audio style="display: none" controls :src="charecters[firest].secound[secound].audio"
                        id="audio"></audio>
                  </span>
                </div>
              </div>
              <div class="person-description">
                <!-- <img style="width:561px;margin:0 auto;position:relative;left:96px" src="@/assets/part-03/text.png" /> -->
                <img style="
                  width: 561px;
                  position: relative;
                  left: 132px;
                " :src="
                  translate(
                    'characters.des.' +
                    charecters[firest].secound[secound].description
                  )
                " />
              </div>
            </div>
          </transition>

        </div>
        <div class="person-right">
          <moveRound :menus="charecters[firest].secound" @getPerson="getPerson" />
        </div>
      </div>
    </div>
    <div class="down-line"></div>
  </div>
</template>

<script>
import Locale from "@/mixins/locale";
import moveRound from "../../components/moveRound.vue";
export default {
  mixins: [Locale],
  components: {
    moveRound,
  },
  data() {
    const charecters = [
      {
        firest: require("@/assets/part-03/left01.png"),
        secound: [
          {
            icon: require("@/assets/part-03/right01-04.png"),
            title: "name6",
            description: "des6",
            person: require("@/assets/gif/woman/800021.gif"),
            audio: require("@/assets/audio/800021.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-05.png"),
            title: "name7",
            description: "des7",
            person: require("@/assets/gif/woman/800027.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right01-03.png"),
            title: "name8",
            description: "des8",
            person: require("@/assets/gif/woman/800008.gif"),
            audio: require("@/assets/audio/800008.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-02.png"),
            title: "name9",
            description: "des9",
            person: require("@/assets//gif/woman/500055.gif"),
            audio: require("@/assets/audio/500055.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-01.png"),
            title: "name10",
            description: "des10",
            person: require("@/assets/gif/woman/800001.gif"),
            audio: require("@/assets/audio/800001.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-04.png"),
            title: "name6",
            description: "des6",
            person: require("@/assets/gif/woman/800021.gif"),
            audio: require("@/assets/audio/800021.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-05.png"),
            title: "name7",
            description: "des7",
            person: require("@/assets/gif/woman/800027.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right01-03.png"),
            title: "name8",
            description: "des8",
            person: require("@/assets/gif/woman/800008.gif"),
            audio: require("@/assets/audio/800008.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-02.png"),
            title: "name9",
            description: "des9",
            person: require("@/assets/gif/woman/500055.gif"),
            audio: require("@/assets/audio/500055.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-01.png"),
            title: "name10",
            description: "des10",
            person: require("@/assets/gif/woman/800001.gif"),
            audio: require("@/assets/audio/800001.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-04.png"),
            title: "name6",
            description: "des6",
            person: require("@/assets/gif/woman/800021.gif"),
            audio: require("@/assets/audio/800021.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-05.png"),
            title: "name7",
            description: "des7",
            person: require("@/assets/gif/woman/800027.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right01-03.png"),
            title: "name8",
            description: "des8",
            person: require("@/assets/gif/woman/800008.gif"),
            audio: require("@/assets/audio/800008.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-02.png"),
            title: "name9",
            description: "des9",
            person: require("@/assets/gif/woman/500055.gif"),
            audio: require("@/assets/audio/500055.mp3"),
          },
          {
            icon: require("@/assets/part-03/right01-01.png"),
            title: "name10",
            description: "des10",
            person: require("@/assets/gif/woman/800001.gif"),
            audio: require("@/assets/audio/800001.mp3"),
          },
        ],
      },
      {
        firest: require("@/assets/part-03/left02.png"),
        secound: [
          {
            icon: require("@/assets/part-03/right02-02.png"),
            title: "name1",
            description: "des1",
            person: require("@/assets/gif/man/500060.gif"),
            audio: require("@/assets/audio/500060.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-04.png"),
            title: "name2",
            description: "des2",
            person: require("@/assets/gif/man/500052.gif"),
            audio: require("@/assets/audio/500052.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-05.png"),
            title: "name3",
            description: "des3",
            person: require("@/assets/gif/man/501001.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right02-01.png"),
            title: "name4",
            description: "des4",
            person: require("@/assets/gif/man/500059.gif"),
            audio: require("@/assets/audio/500059.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-03.png"),
            title: "name5",
            description: "des5",
            person: require("@/assets/gif/man/500044.gif"),
            audio: require("@/assets/audio/500044.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-02.png"),
            title: "name1",
            description: "des1",
            person: require("@/assets/gif/man/500060.gif"),
            audio: require("@/assets/audio/500060.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-04.png"),
            title: "name2",
            description: "des2",
            person: require("@/assets/gif/man/500052.gif"),
            audio: require("@/assets/audio/500052.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-05.png"),
            title: "name3",
            description: "des3",
            person: require("@/assets/gif/man/501001.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right02-01.png"),
            title: "name4",
            description: "des4",
            person: require("@/assets/gif/man/500059.gif"),
            audio: require("@/assets/audio/500059.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-03.png"),
            title: "name5",
            description: "des5",
            person: require("@/assets/gif/man/500044.gif"),
            audio: require("@/assets/audio/500044.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-02.png"),
            title: "name1",
            description: "des1",
            person: require("@/assets/gif/man/500060.gif"),
            audio: require("@/assets/audio/500060.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-04.png"),
            title: "name2",
            description: "des2",
            person: require("@/assets/gif/man/500052.gif"),
            audio: require("@/assets/audio/500052.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-05.png"),
            title: "name3",
            description: "des3",
            person: require("@/assets/gif/man/501001.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right02-01.png"),
            title: "name4",
            description: "des4",
            person: require("@/assets/gif/man/500059.gif"),
            audio: require("@/assets/audio/500059.mp3"),
          },
          {
            icon: require("@/assets/part-03/right02-03.png"),
            title: "name5",
            description: "des5",
            person: require("@/assets/gif/man/500044.gif"),
            audio: require("@/assets/audio/500044.mp3"),
          },
        ],
      },
      {
        firest: require("@/assets/part-03/left03.png"),
        secound: [
          {
            icon: require("@/assets/part-03/right03-03.png"),
            title: "name11",
            description: "des11",
            person: require("@/assets/gif/animal/400033.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-01.png"),
            title: "name12",
            description: "des12",
            person: require("@/assets/gif/animal/400032.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-02.png"),
            title: "name13",
            description: "des13",
            person: require("@/assets/gif/animal/400026.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-04.png"),
            title: "name14",
            description: "des14",
            person: require("@/assets/gif/animal/400027.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-05.png"),
            title: "name15",
            description: "des15",
            person: require("@/assets/gif/animal/400028.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-03.png"),
            title: "name11",
            description: "des11",
            person: require("@/assets/gif/animal/400033.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-01.png"),
            title: "name12",
            description: "des12",
            person: require("@/assets/gif/animal/400032.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-02.png"),
            title: "name13",
            description: "des13",
            person: require("@/assets/gif/animal/400026.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-04.png"),
            title: "name14",
            description: "des14",
            person: require("@/assets/gif/animal/400027.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-05.png"),
            title: "name15",
            description: "des15",
            person: require("@/assets/gif/animal/400028.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-03.png"),
            title: "name11",
            description: "des11",
            person: require("@/assets/gif/animal/400033.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-01.png"),
            title: "name12",
            description: "des12",
            person: require("@/assets/gif/animal/400032.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-02.png"),
            title: "name13",
            description: "des13",
            person: require("@/assets/gif/animal/400026.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-04.png"),
            title: "name14",
            description: "des14",
            person: require("@/assets/gif/animal/400027.gif"),
            audio: "",
          },
          {
            icon: require("@/assets/part-03/right03-05.png"),
            title: "name15",
            description: "des15",
            person: require("@/assets/gif/animal/400028.gif"),
            audio: "",
          },
        ],
      },
    ];
    return {
      charecters,
      firest: 0,
      secound: 4,
      playAudio: true,
      audio: "",
      showPerson: true,
      loading: false,
      isPlaying: false
    };
  },
  methods: {
    handleFirst(index) {
      Promise.all([
        this.showPerson = !this.showPerson
      ]).then(res => {
        this.changeShow();
        this.firest = index
        this.isPlaying = false
      })
    },
    changeShow() {
      this.showPerson = !this.showPerson
    },
    handleAudio() {
      var audio = document.querySelector('#audio');
      if (!this.isPlaying) {
        audio.play();
        this.isPlaying = true
        audio.addEventListener('ended',()=> {
          this.$set(this,'isPlaying',false)
        }, true);
      } else {
        setTimeout(() => {
          audio.pause();
        }, 100);
        this.isPlaying = false
      }
    },
    getPerson(index) {
      Promise.all([
        this.showPerson = !this.showPerson
      ]).then(res => {
        this.changeShow();
        this.secound = index;
        this.isPlaying = false
      })
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.main-charecters {
  // width: 1920px;
  max-width: 1920px;
  height: 1080px;
  background: url(../../assets/part-03/bg1.png);
  background-size: 100% 100%;
}

.btn-audio {
  width: 50px;
  height: 50px;
  margin-left: 460px;
  margin-top: -288px;
}

.person-description {
  // margin-left: 72px;
}

.charecters-title {
  width: 100%;
}

.title {
  width: 985px;
  height: 225px;
  margin: 0 auto;
  background: url(../../assets/part-03/bg-title.png);
}

.charecters-content {
  width: 100%;
  height: 800px;
  /* background: red; */
}

.content {
  width: 100%;
  height: 800px;
}

.person-left {
  background: url(../../assets/part-03/left-bg.png);
  width: 234px;
  height: 542px;
  float: left;
  position: relative;
  top: 40px;
  padding-left: 124px;

  .active {
    .btn-first {
      width: 140px;
      height: 140px;
      background-image: url(../../assets/part-03/left-active-bg.png);
      background-size: 100% 100%;
      animation: rotate2 10s linear infinite;

      // position: relative;
      // top: 20px;
      img {
        width: 130px;
        height: 130px;
        position: relative;
        top: 5px;
        left: 5px;
        animation: rotate3 10s linear infinite;
      }
    }
  }
}

@keyframes rotate2 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate3 {
  0% {
    -webkit-transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

.person-left {
  li:nth-of-type(1) {
    margin-left: -20px;
  }

  li:nth-of-type(2) {
    margin-top: 56px;
    margin-left: 24px;
  }

  li:nth-of-type(3) {
    margin-top: 60px;
    margin-left: -16px;
  }
}

.btn-first {
  width: 110px;
  height: 110px;
  margin-top: 40px;
  background-image: url(../../assets/part-03/left-select-bg.png);
  background-size: 100% 100%;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.person-main {
  width: 1280px;
  height: 800px;
  position: relative;
  left: 40px;
  float: left;

  .person-main-left {
    width: 50%;
    height: 100%;
    float: left;
    // background: black;
  }

  .person-main-right {
    width: 50%;
    height: 100%;
    float: right;
    // background: #123456;
  }
}

.person-name {
  width: 528px;
  height: 266px;
  // background: url(../../assets/part-03/center-bg.png);
}

.person-right {
  background: url(../../assets/part-03/right-bg.png);
  width: 312px;
  height: 864px;
  float: right;
  margin-top: -108px;
  padding-top: 24px;
  position: relative;

  .active {
    width: 152px;
    height: 174px;
  }
}

.btn-secound {
  width: 132px;
  height: 154px;
}

.down-line {
  // width: 1920px;
  max-width: 1920px;
  height: 20px;
  background: url(../../assets/part-03/down-line.png);
  background-size: cover;
  // margin-top: -50px;
  position: relative;
}

.fade-leave,
// 离开前,进入后透明度是1
.fade-enter-to {
  opacity: 1;
}

.fade-leave-active,
.fade-enter-active {
  transition: all 3s; //过度是2秒
}

.fade-leave-to,
.fade-enter {
  opacity: 0;
}
</style>

