<template>
  <div class="main-feacters">
    <div class="feacters-title">
      <div class="title" :style="btnTitleStyle">
        <img style="width:320px;height:91px;position: relative;top:88px;left:332px;" :src="$t('features.title')" />
      </div>
    </div>
    <div class="feacters-swiper">
      <VueSwiper />
    </div>
    <div class="line-bottom"></div>
  </div>
</template>

<script>
import VueSwiper from '@/components/swiper.vue'

export default{
  components:{
    VueSwiper
  },
  computed:{
    btnTitleStyle(){
      return{
        background: "url(" + this.$t('features.bg') + ")"
      }
    }
  },
}
</script>


<style scoped>
.main-feacters{
  /* width:1920px; */
  max-width: 1920px;
  max-height: 1080px;
  background: url(../../assets/part-04/bg1.png);
  background-size: 100% 100%;
}
.feacters-title{
  width: 100%;
}
.title{
  width: 985px;
  height:255px;
  margin: 0 auto;
  /* background: url(../../assets/part-04/bg-title.png); */
}
.feacters-swiper{
  margin-top: -40px;
}
.line-bottom{
  background: url(../../assets/part-04/down-line.png);
  width: 100%;
  background-size: cover;
  height: 20px;
  margin-top: -40px;
}
</style>