<template>
  <div class="main-side">
    <div class="side-box" :class="{ toright: isRight }" v-if="scrollTop>970 && scrollTop<4280">
      <div class="side-person">
        <img v-if="scrollTop>=970 && scrollTop<1800" style="width:106px;height:152px;position:relative;top: -80px;left: 8px;" src="@/assets/part-01/right-person1.png" />
        <img v-else-if="scrollTop>=1800 && scrollTop<2826" style="width:106px;height:152px;position: relative;top: -80px;left: 16px;" src="@/assets/part-01/right-person2.png" />
        <img v-else-if="scrollTop>=2826 && scrollTop<4280" style="width:106px;height:152px;position: relative;top: -80px;" src="@/assets/part-01/right-person3.png" />
      </div>
      <div class="side-title">
        <ul class="li-title">
          <li v-for="(nav, index) in rightNavList" :key="index" :class="[{ active: activeRight == nav }]" @click="handleSelect(nav,index)">
            <div class="div-li">
              <img :src="translate('navRight.' + nav)" />
            </div> 
            <img class="div-line" style="width:82px;height:1px;margin-left:18px;margin-top: -24px;" src="@/assets/part-01/right-line.png" />
          </li>
          <!-- <li><vui-button type="text" @click="handleSelect('news')"><img src="@/assets/part-01/right-news_zh.png" /></vui-button></li><div style="background:url(../../assets/part-01/right-line.png);width: 82px;height: 1px;"></div>
          <li><vui-button type="text" @click="handleSelect('charecters')"><img src="@/assets/part-01/right-charecters_zh.png" /></vui-button></li><div style="background:url(../../assets/part-01/right-line.png);width: 82px;height: 1px;"></div>
          <li><vui-button type="text" @click="handleSelect('features')"><img src="@/assets/part-01/right-features.png" /></vui-button></li><div style="background:url(../../assets/part-01/right-line.png);width: 82px;height: 1px;"></div>
          <li><vui-button type="text" @click="handleSelect('benefits')"><img src="@/assets/part-01/right-benefits_zh.png" /></vui-button></li><div style="background:url(../../assets/part-01/right-line.png);width: 82px;height: 1px;"></div>
          <li><vui-button type="text" @click="handleSelect('value')"><img src="@/assets/part-01/right-value_zh.png" /></vui-button></li> -->
        </ul>
      </div>
      <div class="side-btn">
        <vui-button type="text" @click="handleToApp"><img style="width:104px;height:34px;margin-left:-8px;margin-bottom: 8px;" src="@/assets/part-01/right-app.png" /></vui-button>
        <vui-button type="text" @click="handleToGoogle"><img style="width:104px;height:34px;margin-left:-8px;" src="@/assets/part-01/right-google.png" /></vui-button>
      </div>
      <div class="tootop">
        <vui-button type="text" class="btn-top" @click="handleToTop">
          <img class="btn-top" src="@/assets/part-01/right-TOP.png" />
        </vui-button>
      </div>
    </div>
    <div v-if="showPopup == true">
      <ShowPopup :showPopup="showPopup" @update="update" />
    </div>
  </div>
</template>

<script>
import Locale from "@/mixins/locale"
import ShowPopup from '../../components/show-popup.vue'

export default{
  mixins: [
    Locale
  ],
  components:{
    ShowPopup
  },
  data(){
    return{
      rightNavList:['news','characters','features','benefits','value'],
      activeRight: '',
      isRight: false,
      scrollTop: '',
      showPopup: false,
    }
  },
  methods:{
    handleToApp(){
      window.open('https://apps.apple.com/us/app/imperial-destiny/id6444196796',"_blank")
    },
    handleToGoogle(){
      window.open('https://play.google.com/store/apps/details?id=com.overseas.thereturnofprinceali',"_blank")
    },
    handleToTop(){
      document.querySelector('#home').scrollIntoView(true);
    },
    handleSelect(nav,index){
      if(nav == 'benefits'){
        this.showPopup = true
        this.activeRight = nav;
      }else if(nav == 'value'){
        this.showPopup = false
        window.open('https://pay.kffgame.com/PCPay/1145587784/DHHH',"_blank")
      }else{
        this.showPopup = false
      this.activeRight = nav;
      document.querySelector(`#${nav}`).scrollIntoView(true);
      }
    },
    update(newValue){
      this.showPopup = newValue
    },
    scrollToTop(){
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop
      if (scrollTop) {
        this.isRight = true;
        if(scrollTop<630){
          this.activeNav = 'home'
        }
        else if(scrollTop>630 && scrollTop<1716){
          this.activeNav = 'news'
          this.activeRight = 'news'
        }
        else if(scrollTop>1716 && scrollTop<2724){
          this.activeNav = 'characters'
          this.activeRight = 'characters'
        }
        else if(scrollTop>2724){
          this.activeNav = 'features'
          this.activeRight = 'features'
        }
      } else {
        this.isRight = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
}
</script>

<style scoped lang="less">
.main-side{
  width: 100%;
}
.toright{
  position: fixed;
  top: 20%;
  z-index: 999;
}
.side-box{
  background: url(../../assets/part-01/right-bg.png);
  width: 128px;
  height: 386px;
  float: right;
  /* margin-right: 20px; */
  left: 92%;
  z-index: 1000;
}
.side-title{
  width: 118px;
  height: 200px;
  /* background: red; */
  margin: auto;
  margin-top: -100px;
}
.li-title{
  width: 100%;
  height: 260px;
  // background: red;
}
.div-li{
  width: 100%;
  height: 52px;
  // background: blue;
  margin: 0 auto;
  img{
    width: 104px;
    height: 40px;
    position: relative;
    left: 6px;
    top: 5px;
  }
}
.li-title li{
  width: 100%;
  height: 58px;
  cursor: pointer;
}
.li-title li:not(:first-child){
  margin-top: -12px;
}
.li-title li:last-child .div-line{
  opacity: 0 !important;
}

  .active .div-li{
    background: url(../../assets/part-01/bg-li-title.png);
    background-size: cover;
    width: 110px;
    height: 45px;
    // background: red;
  }
  .active .div-li img{
    width: 110px;
    height: 45px;
    position: relative;
    left: -1px;
  }

.side-btn{
  width: 120px;
  height: 70px;
  /* background: red; */
  margin: auto;
  position: relative;
    top: 27px;
}
.btn-top{
  height: 58px;
  width: 58px;
  top: 30px;
  left: 22px;
}
</style>