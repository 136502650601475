<template>
  <div class="main-home">
    <div class="popup" v-if="showPopup == true" @touchmove.prevent @mousewheel.prevent>
      <div class="popup-content">
        <img src="@/assets/part-01/popup-text.png"
          style="height:120px;width:380px;position:relative;top:26%;left:30%" />
        <vui-button type="text" class="btn-x" @click="handleClose"><img class="btn-x"
            src="@/assets/part-01/x.png" /></vui-button>
      </div>
    </div>
    <div class="home-btn">
      <div class="show-video">
        <div class="show-video-bg1">
          <!-- <div class="show-video-bg2">
            <vui-button type="text" @click="handleToVideo" class="btn-img"
          style="width:175px;height:166px;"><img class="video-img"
            src="@/assets/part-01/center-video.png" /></vui-button>
          </div> -->
        </div>
        <div class="show-video-bg2"></div>
        <vui-button type="text" @click="handleToVideo" class="btn-img"
          style="width:175px;height:166px;"><img class="video-img"
            src="@/assets/part-01/center-video.png" /></vui-button>

      <div class="mask-overall" v-if="videoState == true">
        <div class="mask-main">
          <video src="@/assets/video/YD_yzj005R_vdo_2D_juqing_shangdianshiping_EN_30s_1920x1080.mp4" controls autoplay width="100%" height="100%">
            您的浏览器不支持 video 标签。
          </video>
          <vui-button style="position:relative;width:36px;height:36px;float: right;top: -670px;right: -40px;" type="text" @click="closeMsk()"><img style="position:relative;width:36px;height:36px;filter: brightness(10);" src="@/assets/part-01/x1.png" /></vui-button>
        </div>
      </div>

      </div>
      <div class="text" style="margin:0 auto;width:1171px;height:147px">
        <img style="position:relative;width:1171px;height:257px;top:-40px" :src="translate('home.text')" />
      </div>
      <div class="btn-download">
        <span style="float:left;">
          <vui-button type="text" @click="handleDownApp" class="btn"><img
              src="@/assets/part-01/down-app.png" /></vui-button>
        </span>
        <span style="float:left;position: relative;left: 70px;">
          <vui-button type="text" @click="handleDownGoogle" class="btn"><img
              src="@/assets/part-01/down-google.png" /></vui-button>
        </span>
        <span style="float:right;">
          <vui-button type="text" @click="handleDownAndroid" class="btn1"><img style="width:210px;height:68px"
              src="@/assets/part-01/down-Android.png" /></vui-button>
        </span>
      </div>
      <div class="btn-glide">
        <div class="animation-img">
        </div>
        <vui-button type="text" style="width:198px;height:102px;position:relative;bottom:152px;left:864px"
          @click="handleToNext"><img :src="$t('home.glide')" /></vui-button>
      </div>
    </div>
    <div v-if="showPopup1 == true">
	    <ShowPopup :showPopup="showPopup" @update="update" />
	  </div>
  </div>
</template>

<script>
import Locale from "@/mixins/locale"
import ShowPopup from '../../components/show-popup.vue'
export default {
  mixins: [Locale],
  data() {
    return {
      showPopup: false,
      videoState: false,
      showPopup1: false
    }
  },
  components:{
    ShowPopup
  },
  watch: {
    videoState(newVal){
      if(newVal){
        this.stop()
      }else{
        this.move()
      }
    },
  },
  methods: {
    closePopup() {
      this.isPopup = false;
    },
    update(newValue){
      this.showPopup1 = newValue
	  },
    mousewheel(e) {
      e.preventDefault();
    },
    handleDownApp() {
      window.open('https://apps.apple.com/us/app/imperial-destiny/id6444196796', "_blank")
    },
    handleDownGoogle() {
      window.open('https://play.google.com/store/apps/details?id=com.overseas.thereturnofprinceali', "_blank")
    },
    handleToVideo() {
      this.videoState = true
    },
    handleDownAndroid(){
      // console.log('安卓下载')
      //this.showPopup1 = true
        window.open('https://c-dhhdhxjp.coco4games.com/ImperialDestiny.apk', "_blank")
    },
    stop(){
      var mo=function(e){e.preventDefault();};
      document.body.style.overflow='hidden';
      document.addEventListener("touchmove",mo,false);//禁止页面滑动
    },
    closeMsk(){
      this.videoState = false
    },
    move(){
      var mo=function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",mo,false);
    },
    handleClose() {
      this.showPopup = false
    },
    handleToNext() {
      document.querySelector('#news').scrollIntoView(true);
    },
    masksCloseFun() {
      this.videoState = false;
    },
  }
}
</script>

<style scoped>
.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup-content {
  background: url(../../assets/part-01/popup.png);
  height: 246px;
  width: 684px;
  position: absolute;
  top: 40%;
  left: 34%;
}

.btn-x {
  width: 12px;
  height: 12px;
  position: relative;
  top: -12px;
  left: 116px;
}

.main-home {
  /* width: 1920px; */
  max-width: 1920px;
  height: 1080px;
  background: url(../../assets/part-01/bg1.png);
  background-size: cover;
  background-position-x: center;
}

.home-btn {
  width: 100%;
  height: 33%;
  /* background: red; */
  position: relative;
  top: 67%;
}

.show-video {
  margin: 0 auto;
  /* margin-left:370px; */
  width: 1083px;
  height: 22px;
  background: url(../../assets/part-01/bg-line.png);
  background-size: cover;
  position: relative;
  /* left: -20px; */
  top: -18px;
  z-index: 20;
}

.show-video-bg1,
.show-video-bg2,
.btn-img{
  position: absolute;
  top: -86px;
  left: 456px;
}

.show-video-bg1 {
  width: 175px;
  height: 166px;
  background: url(../../assets/part-01/bg1-video.png);
  /* transform: rotateX(70deg); */
  animation: circle 15s linear infinite;
  /* position: relative; */
  /* top: -80px; */
  transform: rotateX(70deg);
  animation: circle 18s linear infinite;
}

@keyframes circle {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.show-video-bg2 {
  width: 175px;
  height: 166px;
  /* position: relative;
  top: -256px;
  left: 30.5px; */
  background: url(../../assets/part-01/bg2-video.png);
  -webkit-animation: rotation 15s linear infinite;
  animation: rotation1 15s linear infinite;
}

@keyframes rotation1 {
  0% {
    -webkit-transform: rotate(360deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

.video-img {
  width: 175px;
  height: 166px;
  position: relative;
  left: -14px;
}

.video-img {
  animation: breathe 1.1s infinite;
}

@keyframes breathe {
  0% {
    transform: scale(.95);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(.95);
  }
}
.video-img:hover{
  animation: breathe1 1.5s infinite;
}
@keyframes breathe1 {
  0% {
    transform: scale(.95);
  }

  40% {
    transform: scale(1.08);
  }

  50% {
    transform: scale(1.05);
  }

  60% {
    transform: scale(1.08);
  }

  100% {
    transform: scale(.95);
  }
}

.btn-download {
  width: 900px;
  height: 68px;
  margin: 0 auto;
  position: relative;
  top: 48px;
}

.btn-download span,
.btn {
  width: 249px;
  height: 68px;
}
.btn1,
.btn1 img {
  width: 210px;
  height: 68px;
}

.btn-download img {
  width: 249px;
  height: 68px;
}

.btn-glide {
  width: 100%;
  height: 20px;
  margin: 0 auto;
  background: url(../../assets/part-01/bottom-line.png);
  margin-top: 88px;
  background-size: cover;
}

.animation-img {
  width: 198px;
  height: 102px;
  margin: 0 auto;
  background: url(../../assets/part-01/bg-glide.png);
  animation: jumpBoxHandler 1s infinite;
  position: relative;
  top: -48px;
}

@keyframes jumpBoxHandler {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, 8px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.mask-overall{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  height: 100%;
  animation: move .3s linear;
  animation-fill-mode:forwards;
}
@keyframes move{
    0%{opacity:0;}
    100%{opacity:1;}
  }
  .mask-main{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: 1200px;
  }
</style>