export default {
  methods: {
    translate(path) {
      if (!this.$te || !this.$t) {
        return path;
      }

      return this.$te(path) ? this.$t(path) : path;
    }
  }
};