<template>
  <div id="vue-swiper">
    <div class="swiper-container" ref="swiperTop">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="@/assets/part-04/swiper1.png" />
        </div>
        <div class="swiper-slide">
          <img src="@/assets/part-04/swiper2.png" />
        </div>
        <div class="swiper-slide">
          <img src="@/assets/part-04/swiper3.png" />
        </div>
        <div class="swiper-slide">
          <img src="@/assets/part-04/swiper4.png" />
        </div>
        <div class="swiper-slide">
          <img src="@/assets/part-04/swiper5.png" />
        </div>
        <div class="swiper-slide">
          <img src="@/assets/part-04/swiper6.png" />
        </div>
      </div>
    </div>
    <div class="swiper-pagination">
      
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";
import Swiper,{ Navigation, Pagination,Autoplay } from "swiper";

Swiper.use([Navigation, Pagination,Autoplay]);

export default {
  components: {
    Swiper,
  },
  methods:{
  },
  mounted() {
    new Swiper(".swiper-container", {
      watchSlidesProgress: true,
      slidesPerView: "auto",
      centeredSlides: true,
      loop: true,
      loopedSlides: 6,
      autoplay: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable :true,
      },
      on: {
        progress(progress) {
          for (let i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            var slideProgress = this.slides[i].progress;
            let modify = 1;
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
            }
            let translate = slideProgress * modify * 1000 + "px";
            let scale = 1 - Math.abs(slideProgress) / 5;
            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform(
              "translateX(" + translate + ") scale(" + scale + ")"
            );
            slide.css("zIndex", zIndex);
            slide.css("opacity", 1);
            if (Math.abs(slideProgress) > 3) {
              slide.css("opacity", 0);
            }
          }
        },
        setTransition(swiper, transition) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            slide.transition(transition);
          }
        },
      },
    });
  },
};
</script>

<style>
#vue-swiper {
  position: relative;
  width: 1390px;
  height: 800px;
  margin: 0 auto;
}

#vue-swiper .swiper-container {
  padding-bottom: 60px;
  height: 800px;
  /* position: relative; */
  /* top: -2924px; */
}

#vue-swiper .swiper-slide {
  width: 1218px;
  height: 662px;
  opacity: 0 !important;
}

#vue-swiper .swiper-slide img {
  display: block;
  width: 1218px;
  height: 662px;
  /* opacity: 0 !important; */
}

#vue-swiper .swiper-slide-active,
#vue-swiper .swiper-slide-next,
#vue-swiper .swiper-slide-prev{
  opacity: 1 !important;

}

#vue-swiper .swiper-pagination {
  width: 260px;
  height: 1px;
  left: 576px;
  bottom: 96px;
  background: url(../assets/part-04/line.png);
}

#vue-swiper .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
  /* border: 3px solid #fff;
  background-color: #d5d5d5; */
  width: 20px;
  height: 20px;
  opacity: 1;
  background: url(../assets/part-04/select.png);
  position: relative;
  top: -28px;
}

#vue-swiper .swiper-pagination-bullets .swiper-pagination-bullet-active {
  /* border: 3px solid red;
  background-color: #fff; */
  background: url(../assets/part-04/active.png);
  width: 30px;
  height: 38px;
}

#vue-swiper .swiper-button-prev {
  left: -120px;
  width: 182px;
  height: 100px;
  background: url(../assets/part-04/left.png) no-repeat;
  background-position: 0 0;
  background-size: cover;
  position: absolute;
  top:37.5%;
}

#vue-swiper .swiper-button-prev:hover {
  background-position: 0 0;
  background-size: 100%;
}

#vue-swiper .swiper-button-next {
  right: -120px;
  width: 182px;
  height: 100px;
  background: url(../assets/part-04/right.png) no-repeat;
  background-position: 0 0;
  background-size: cover;
  position: absolute;
  top:37.5%;
}

#vue-swiper .swiper-button-next:hover {
  background-position: 0 0;
  background-size: 100%;
}

#vue-swiper .swiper-button-prev::after{ 
  content: ""; 
}
#vue-swiper .swiper-button-next::after {
  content: "";
}
</style>