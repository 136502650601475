<template>
  <div class="vue-main">
    <div class="vue-header">
      <main-header></main-header>
    </div>
    <div class="vue-content">
      <div id="home" ref="home">
        <main-home />
      </div>
      <div id="news" ref="news">
        <main-news />
      </div>
      <div id="characters" ref="characters" style="margin-top:-40px;position:relative;z-index:9">
        <main-charecters />
      </div>
      <div id="features" ref="features" style="margin-top: 0px;position: relative;z-index: 9;">
        <main-feactures />
      </div>
    </div>
    <div class="vue-side">
      <main-side />
    </div>
    <div class="vue-footer">
      <main-footer></main-footer>
    </div>
  </div>
</template>

<script>
  import MainFooter from './main-footer.vue'
  import MainHeader from './main-header.vue'
  import MainHome from './main-home.vue'
  import MainNews from './main-news.vue'
  import MainCharecters from './main-charecters.vue'
  import MainFeactures from './main-feactures.vue'
  import MainSide from './main-side.vue'

  export default{
    components:{
      MainFooter,
      MainHeader,
      MainHome,
      MainNews,
      MainCharecters,
      MainFeactures,
      MainSide
    }
  }
</script>

<style lang="less" scoped>
 .vue-header{ width:100%;height:100px; }
 .vue-content{ width: 100%;margin-top:-200px; }
 .vue-footer{ width:100%;height:200px;text-align: center;margin-bottom:0px; }
</style>
