<template>
  <div class="main-header">
    <div class="navigation totop">
      <div class="image-link">
        <vui-button
          style="width: 92px; height: 92px; margin-left: 24px; margin-top: 8px"
          type="text"
          @click="handleHome"
        >
          <img
            v-if="showPhoto == true"
            style="width: 70px; height: 70px;position: relative;top: -14px;left: 16px;"
            src="@/assets/navigation-bar/logo1.png"
          />
          <img
            v-else
            style="width: 129px; height: 54px; margin-top: -32px;margin-left: -12px;"
            src="@/assets/navigation-bar/logo2.png"
          />
        </vui-button>
      </div>
      <div class="text-link">
        <ul class="text">
          <li
            v-for="(nav, index) in navList"
            :key="index"
            :class="[{ active: activeNav == nav }]"
            @click="handleSelect(nav, index)"
          >
            <img :src="translate('nav.' + nav)" />
          </li>
        </ul>
      </div>
      <div class="btn-link">
        <span
          class="btn"
          style="margin-right: 24px; float: left; margin-top: 24px"
        >
          <vui-button class="btn" type="text" @click="handleToFB">
            <img class="btn" src="@/assets/navigation-bar/FB.png" />
          </vui-button>
        </span>
        <span
          class="btn"
          style="margin-right: 24px; float: left; margin-top: 24px"
        >
          <vui-button class="btn" type="text" @click="handleToDC">
            <img class="btn" src="@/assets/navigation-bar/Discord.png" />
          </vui-button>
        </span>
        <span class="btn" style="float: left; margin-top: 24px">
          <vui-dropdown trigger="click">
            <vui-button class="btn" type="text">
              <img class="btn" src="@/assets/navigation-bar/lang.png" />
            </vui-button>
            <vui-dropdown-menu
              slot="menu"
              @click="handleLangChange"
              style="width: 120px; float: right; margin-top: 24px"
            >
              <!-- <vui-dropdown-menu-item value="1" title="繁体中文" />
              <vui-dropdown-menu-item value="2" title="English" /> -->
              <vui-dropdown-menu-item value="1" :style="value==1 ? 'color: #ffba85' : ''" title="繁体中文" :to="{name:'Main',params:{language:'cn'}}" />
              <vui-dropdown-menu-item value="2" :style="value==2 ? 'color: #ffba85' : ''" title="English" :to="{name:'Main',params:{language:'en'}}" />
            </vui-dropdown-menu>
          </vui-dropdown>
        </span>
      </div>
    </div>
    <div v-if="showPopup == true">
      <ShowPopup :showPopup="showPopup" @update="update" />
    </div>
  </div>
</template>

<script>
import Locale from "@/mixins/locale";
import ShowPopup from '../../components/show-popup.vue'

const language = localStorage.language || "en-US";

const maps = {
  "zh-CN": "cn",
  "en-US": "en",
};

const mapReverse = {
  cn: "zh-CN",
  en: "en-US",
};

export default {
  mixins: [Locale],
  components:{
    ShowPopup
  },
  data() {
    return {
      navList: ["home", "news", "characters", "features", "benefits", "value"],
      activeNav: "home",
      isTop: false,
      showPhoto: true,
      value: 1,
      showPopup: false
    };
  },
  watch: {
    "$route.params.language": {
      handler(language) {
        console.log("language => ", language);
        const lang = mapReverse[language];
      
        this.$i18n.locale = lang;
      },
      immediate: true
    },
  },
  methods: {
    handleHome() {
      window.location.href = "";
    },
    handleSelect(nav, index) {
      if(nav == 'benefits'){
        this.showPopup = true
        this.activeNav = nav;
      }else if(nav == 'value'){
        window.open('https://pay.kffgame.com/PCPay/1145587784/DHHH',"_blank")
      }else{
        this.showPopup = false
        this.activeNav = nav;
        document.querySelector(`#${nav}`).scrollIntoView(true);
      }
    },
    update(newValue){
      this.showPopup = newValue
    },
    handleToFB() {
      window.open("https://www.facebook.com/ImperialDestinyPOG/", "_blank");
    },
    handleToDC() {
      window.open("https://discord.gg/ayeD5pW8BF", "_black");
    },
    scrollToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop) {
        this.isTop = true;
        this.showPhoto = false;
        if (scrollTop < 630) {
          this.activeNav = "home";
        } else if (scrollTop > 630 && scrollTop < 1716) {
          this.activeNav = "news";
        } else if (scrollTop > 1716 && scrollTop < 2724) {
          this.activeNav = "characters";
        } else if (scrollTop > 2724) {
          this.activeNav = "features";
        }
      } else {
        this.isTop = false;
        this.showPhoto = true;
      }
    },
    handleLangChange(value) {
      this.value = value
      console.log(this.$router,'router')
      // if (value == 1) {
      //   this.$router.push("/cn");
      // } else if (value == 2) {
      //   this.$router.push("/en");
      // }
    },
  },
  created(){
    if(this.$i18n.locale == 'zh-CN'){
	    this.handleLangChange(1)
	  }else if(this.$i18n.locale == 'en-US'){
	    this.handleLangChange(2)
	  }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
};
</script>

<style lang="less">

.vui-dropdown-menu-light{
  background: rgba(0, 0, 0, 0.5);
}
.vui-dropdown-menu-light .vui-dropdown-menu-item{
	color: #fff;
}
.vui-dropdown-menu-item-title{
  color: #fff;
}
.vui-dropdown-menu-item-title:hover{
  color: #ffba85;
}
.main-header {
  // width: 1920px;
  max-width: 1920px;
}
.navigation {
  // width: 1920px;
  max-width: 1920px;
  height: 78px;
  background: rgba(255, 255, 255, 80%);
}
.image-link {
  width: 10%;
  height: 78px;
  float: left;
}
.text-link {
  width: 60%;
  height: 78px;
  float: left;
}
.totop {
  position: fixed;
  top: 0;
  z-index: 1000;
}
.text {
  padding: 0;
  margin: 0 auto;
  height: 78px;
  border-radius: 8px;
  display: flex;
  .active {
    background: url(../../assets/navigation-bar/bg-text.png);
    img {
      filter: brightness(100);
    }
  }
}
.text li {
  flex: 1;
  cursor: pointer;
}
.text li:hover {
  background: url(../../assets/navigation-bar/bg-text.png);
  img {
    filter: brightness(100);
  }
}
.text li img {
  width: 174px;
  height: 78px;
  position: relative;
  // top: 40%;
  left: 8px;
}
.btn-link {
  width: 10%;
  height: 77px;
  float: right;
}
.btn {
  width: 28px;
  height: 27px;
}
</style>