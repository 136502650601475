<template>
  <div class="main-news">
    <div class="news-title">
      <div class="title">
        <img style="width:320px;height:86px;position: relative;top:96px;left:332px;" :src="$t('news.title')" />
      </div>
    </div>
    <div class="news-content">
      <div class="bg-content1">
        <div class="content">
          <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FImperialDestinyPOG%2F&tabs=timeline&width=500&height=800&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=655952226175763" style="border:none;overflow:hidden;width:500px;height: 612px;margin-left: 240px;" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
        <div class="news-btn">
          <span class="btn-like" :style="btnLikeStyle">
            <div class="fb-like" data-href="https://www.facebook.com/ImperialDestinyPOG/" data-width="" data-layout="button" data-action="like" data-size="large" data-share="false"></div>
        </span>

        <span class="btn-share" :style="btnShareStyle">
          <div class="fb-share-button" data-href="https://www.facebook.com/ImperialDestinyPOG/" data-layout="button" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.facebook.com%2FImperialDestinyPOG%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore"></a></div>
        </span>
        </div>
      </div>
      <vui-alert v-if="showMessage" class="alert" type="warning" style="width: 300px;position: relative;top: -500px;z-index: 99;left: 54px;" showIcon :message="$t('news.tip')" banner closable />
    </div>
    <div class="line-bottom"></div>
  </div>
</template>

<script>
const loadScript = function(url, success, error) {
	var script = document.createElement("script");

	if (script.readyState) {
		script.onreadystatechange = function() {
			if (script.readyState == "loaded" || script.readyState == "complete") {
				script.onreadystatechange = null;

				if (typeof success === "function") {
					success();
				}
			}
			else {
				if (typeof error === "function") {
					error();
				}
			}
		};
	}
	else {
		script.onload = function() {
			if (typeof success === "function") {
				success();
			}
		};
		script.onerror = function() {
			if (typeof error === "function") {
				error();
			}
		};
	}


	script.type = "text/javascript";
	script.src = url;

	document.head.appendChild(script);
};

export default {
  data(){
    return{
      showMessage: false
    }
  },
  computed: {
    btnLikeStyle() {
      return {
        float: "left",
        marginRight: "32px",
        background: "url(" + this.$t('news.like') + ")"
      };
    },
    btnShareStyle() {
      return {
        float: "left",
        background: "url(" + this.$t('news.share') + ")"
      };
    }
  },
  created() {
    loadScript("https://connect.facebook.net/en_US/sdk.js", () => {
		   console.log("success")
	   }, () => {
		   this.showMessage = true
	   })
       window.fbAsyncInit = function() {
       FB.init({
         appId      : '655952226175763',
         cookie     : true,
         xfbml      : true,
         version    : 'v15.0'
       });

       // FB.AppEvents.logPageView();
       FB.getLoginStatus(function(response) {
         console.log(response);
      });

    };


     (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));


  },
};
</script>

<style>
.btn-share{
  position: relative;
  overflow: hidden;
}

.btn-share iframe{
  display: inline-block;
  transform: scale(2.8);
  transform-origin: left top;
  opacity: 0;
}
.btn-like{
  position: relative;
  overflow: hidden;
}

.btn-like iframe{
  display: inline-block;
  transform: scale(2.8);
  transform-origin: left top;
  opacity: 0;
}
</style>
<style scoped>

.bg-content1{
  background: url(../../assets/part-02/ng5-Fb.png);
  width: 1750px;
  height: 900px;
  background-size: cover;
  margin: 0 auto;
  z-index: 9;
  position: relative;
}
.bg-content1:before{
  width: 500px;
  height: 500px;
  background-color: #fff;
}
.main-news{
  max-width: 1920px;
  background: url(../../assets/part-02/bg.png);
  background-size: cover;
}
.alert >>> .vui-alert-icon,
.alert >>> .vui-alert-btn-close{
  width: 10px;
}
.news-title{
  width: 100%;
}
.title{
  width: 985px;
  height: 225px;
  margin: 0 auto;
  background: url(../../assets/part-02/bg-title.png);
}
.content{
  width: 1000px;
  height: 660px;
  margin: 0 auto;
  position: relative;
  top: 126px;
  left: 64px;
}
.news-btn{
  width: 500px;
  height: 64px;
  position: relative;
  top: 40px;
  left: 676px;
}
.btn-share, .btn-like{
  width: 230px;
  height: 64px;
  cursor: pointer;
}
.btn-like /deep/ div,
.btn-share /deep/ div
.btn-like /deep/ div /deep/ span,
.btn-share /deep/ div /deep/ span,
.btn-like /deep/ div /deep/ span /deep/ iframe,
.btn-share /deep/ div /deep/ span /deep/ iframe {
  display: block;

}
.btn-like /deep/ div /deep/ span,
.btn-share /deep/ div /deep/ span {
  position: relative;
  overflow: hidden;
  width: 230px !important;
  height: 64px !important;
}
.btn-like /deep/ div /deep/ span /deep/ iframe,
.btn-share /deep/ div /deep/ span /deep/ iframe {
  position: absolute;
  top: 0;
  left: 10px;
  transform: scale(4);
  transform-origin: 0 0;
  opacity: 0;
  border: none;
    visibility: visible;
    width: 50px !important;
    height: 14px !important;
}
.news-content{
  width:100%;
  height: 900px;
  overflow:hidden;
  margin-top: -100px;
}
.line-bottom{
  background: url(../../assets/part-02/down-line.png);
  width: 100%;
  background-size: cover;
  height: 20px;
  position: relative;
  top: -40px;
}
</style>