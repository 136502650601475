var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-charecters"},[_c('div',{staticClass:"charecters-title"},[_c('div',{staticClass:"title"},[_c('img',{staticStyle:{"width":"320px","height":"91px","position":"relative","top":"94px","left":"332px"},attrs:{"src":_vm.$t('characters.title')}})])]),_c('div',{staticClass:"charecters-content"},[_c('div',{staticClass:"content"},[_c('ul',{staticClass:"person-left"},_vm._l((_vm.charecters),function(item,index){return _c('li',{key:index,class:[{ active: _vm.firest == index }],staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"btn-first"},[_c('img',{attrs:{"src":item.firest},on:{"click":function($event){return _vm.handleFirst(index)}}})])])}),0),_c('div',{staticClass:"person-main"},[_c('div',{staticClass:"person-main-right"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showPerson)?_c('img',{staticStyle:{"width":"807px","height":"819px","position":"relative","top":"-28px","left":"-34px"},attrs:{"src":_vm.charecters[_vm.firest].secound[_vm.secound].person}}):_vm._e()])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.showPerson)?_c('div',{staticClass:"person-main-left"},[_c('div',{staticClass:"person-name"},[_c('div',{staticClass:"person-name-img",staticStyle:{"width":"400px","position":"relative","left":"-32px"}},[_c('span',[_c('img',{staticStyle:{"height":"264px","width":"528px"},attrs:{"src":_vm.translate(
                    'characters.name.' +
                    _vm.charecters[_vm.firest].secound[_vm.secound].title
                  )}})]),(_vm.charecters[_vm.firest].secound[_vm.secound].audio)?_c('span',[_c('vui-button',{staticClass:"btn-audio",attrs:{"type":"text"},on:{"click":function($event){return _vm.handleAudio()}}},[_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":!_vm.isPlaying ? require('@/assets/part-03/center-video.png') : require('@/assets/part-03/center-video-not.png')}})]),_c('audio',{staticStyle:{"display":"none"},attrs:{"controls":"","src":_vm.charecters[_vm.firest].secound[_vm.secound].audio,"id":"audio"}})],1):_vm._e()])]),_c('div',{staticClass:"person-description"},[_c('img',{staticStyle:{"width":"561px","position":"relative","left":"132px"},attrs:{"src":_vm.translate(
                  'characters.des.' +
                  _vm.charecters[_vm.firest].secound[_vm.secound].description
                )}})])]):_vm._e()])],1),_c('div',{staticClass:"person-right"},[_c('moveRound',{attrs:{"menus":_vm.charecters[_vm.firest].secound},on:{"getPerson":_vm.getPerson}})],1)])]),_c('div',{staticClass:"down-line"})])
}
var staticRenderFns = []

export { render, staticRenderFns }